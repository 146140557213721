import React, {useEffect, useState} from 'react';
import { useNavigate, } from 'react-router-dom';
import './styles.css';
import $ from "jquery";
import Logo from '../../images/ig-logo-black.png';
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

function AboutCompany() {
    const navigate = useNavigate();
    const firstName = localStorage.getItem("firstName");
    const lastName = localStorage.getItem("lastName");
    const email = localStorage.getItem("company email");

    const recruiterPhone = localStorage.getItem("phoneNumber");
    const userId = localStorage.getItem("userId"); 
    const userLoginId = localStorage.getItem("userLoginId");
    const companyId =  localStorage.getItem("companyId"); 

    const recruiterName = firstName + " " + lastName;

    const [companyName, setCompanyName] = useState("");
    const [companyDescription, setCompanyDescription] = useState("");
    const [website, setWebsite] = useState("");
    const [linkedIn, setLinkedIn] = useState("");

    const [isFormValid, setIsFormValid] = useState(false);
    
    //end points
    const recruiterInfoEndpoint = process.env.REACT_APP_RECRUITER_ADD_ENDPOINT;

    function createOrBack(e) {
        e.preventDefault();
        navigate('/aboutRecruiter');
    }

    const RequiredSpan = styled(Typography)(() => ({
        fontFamily: "Montserrat, san-serif !important",
        color: "#FF0000",
        display: "inline",
        fontSize: "20px !important",
        marginLeft: "3px",
      }));

    function createAccount() {
        $.ajaxSetup({
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
        });
        localStorage.setItem("companyName", companyName);
        $.post(
            `${recruiterInfoEndpoint}`,
            JSON.stringify({ userId: userId, userLoginId: userLoginId, 
                recruiterName: recruiterName, recruiterEmail: email, recruiterPhone: recruiterPhone, 
                companyId: companyId, companyName: companyName, companyEmail: email, active: true,
                companyDescription: companyDescription, website: website, linkedIn: linkedIn}),
            (data) => {
                navigate("/dashboard");
            }
        ).fail(data3 => {
            console.log(`[API FAILED]: ${data3}`)
        });
    }

    // Effect to check form validity
    useEffect(() => {
        const isValid =
            companyName.trim() &&
            companyDescription.trim() &&
            website.trim() &&
            linkedIn.trim();
        setIsFormValid(isValid); // Update form validity
    }, [companyName, companyDescription, website, linkedIn]);

    return (
            <div className="company-container">
                 <div className='nav-top-2'>
                    <img src={Logo} alt="" onClick={()=>{navigate("/login")}}/>
                </div>

                <div className="company-text-header2">
                    About Your Company
                </div>
                    
                    <section className="company-section">
                        <form className="company-form" onSubmit={createOrBack}>

                            <div className="post-position-form-group">
                                <label className="post-position-label">
                                    Company Name
                                    <RequiredSpan>*</RequiredSpan>
                                </label>
                                <input className="post-position-input" placeholder='e.g. Intern Guys' type="text" onChange={e => setCompanyName(e.target.value)}></input>
                            </div>

                            <div className="company-form-inline">
                                <div className="post-position-form-group">
                                    <label className="post-position-label">
                                        Company Website
                                        <RequiredSpan>*</RequiredSpan>
                                    </label>
                                    <input
                                        className="post-position-input"
                                        placeholder='https://www.internguys.com'
                                        type="text"
                                        onChange={(e) => setWebsite(e.target.value)}
                                    />
                                </div>

                                <div className="post-position-form-group">
                                    <label className="post-position-label">
                                        Company LinkedIn
                                        <RequiredSpan>*</RequiredSpan>
                                    </label>
                                    <input
                                        className="post-position-input"
                                        placeholder='https://www.linkedin.com/company/intern-guys'
                                        type="text"
                                        onChange={(e) => setLinkedIn(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="post-position-form-group">
                                <label className="post-position-label">
                                    Description
                                    <RequiredSpan>*</RequiredSpan>
                                </label>
                                <textarea className="post-position-input" 
                                    type="text" 
                                    placeholder="Short blurb on what your company does and maybe a fun fact!" 
                                    style={{
                                        height: "120px", 
                                    }}
                                    onChange={e => setCompanyDescription(e.target.value)}>
                                </textarea>
                            </div>

                            <div className="company-form-button">
                                <button
                                    className="company-button2"
                                    type="submit"
                                    value="Submit"
                                    onClick={createAccount}
                                    disabled={!isFormValid} // Disable button if form is invalid
                                    style={{
                                        backgroundColor: isFormValid ? "#1DAF5C" : "#ccc", // Change color based on validity
                                        cursor: isFormValid ? "pointer" : "not-allowed", // Change cursor based on validity
                                    }}
                                >
                                    Create Company Profile
                                </button>
                            </div>
                        </form>
                    </section>
            </div>
    )
}

export default AboutCompany;