import React, { useState } from "react";
import "./style.css";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import JobLinkIcon from "../../images/jobLinkIcon.png";
import { useMediaQuery } from '@mui/material';

const PositionInfo = ({ positionInfo, update_refresh }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleDropdownClick = (event) => {
    event.stopPropagation();
  };

  const setPositionInactiveEndpoint = process.env.REACT_APP_SET_POSITION_INACTIVE;
  const setPositionActiveEndpoint = process.env.REACT_APP_SET_POSITION_ACTIVE;

  const routerLocation = useLocation();
  const state = routerLocation.state || {};
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleEditClick = (event) => {
    event.stopPropagation();
    positionInfo.onEditPopup(positionInfo);
  };

  const handleContainerClick = () => {
    navigate(`/job/${positionInfo.companyInterviewId}/applicants`, {
      state: {
        companyName: positionInfo.companyName,
        position: positionInfo.position,
        countCandidateToInterviews: positionInfo.countCandidateToInterviews,
      },
    });
  };

  const handlePopupClick = (event) => {
    event.stopPropagation(); // Prevent triggering other click events
    setIsPopupOpen((prev) => !prev); // Toggle the popup state
    positionInfo.onOpenPopup(position, jobLink);
  };

  const Text = styled(Typography)(() => ({
    fontFamily: "Montserrat, san-serif !important",
    textTransform: "capitalize",
  }));

  const {
    position,
    active,
    countCandidateToInterviews,
    companyInterviewId,
    jobLink,
  } = positionInfo;

  const handleStatusChange = (status) => {
    const endpoint =
      status === "Open" ? setPositionActiveEndpoint : setPositionInactiveEndpoint;

    // Prepare the payload
    const data = companyInterviewId; // Ensure this is a string or valid GUID

    // Use fetch to send JSON data
    fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ companyInterviewId: data }), // Ensure correct structure
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((error) => {
            throw new Error(error.message || "Error occurred");
          });
        }
        return response.json();
      })
      .then((responseData) => {
        update_refresh(); // Refresh the parent component or UI
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="internship-info-container" onClick={handleContainerClick}>
      <div className="internship-info-item internship-status-container"
        style={{ flexDirection: "row", justifyContent: "center" }}
        onClick={handleDropdownClick}>
        <FiberManualRecordIcon
          style={{
            fontSize: "18px",
            color: active === false ? "red" : "green",
            paddingRight: "5px",
          }}
        />

        {!isMobile && ( 
          <Text>{active === false ? "Paused" : "Open"}</Text>
        )}
        
        <div className="internship-dropdown-status">
          {["Open", "Paused"].map((status, index) => (
            <div
              key={index}
              className="internship-drop-mini-container"
              onClick={() => handleStatusChange(status)}
            >
              <FiberManualRecordIcon
                style={{
                  fontSize: "18px",
                  color: status === "Open" ? "green" : "red",
                  paddingRight: "5px",
                }}
              />
              <Text>{status}</Text>
            </div>
          ))}
        </div>

        {!isMobile && ( 
          <KeyboardArrowDownIcon style={{ marginLeft: "15px" }} />
        )}
      </div>

      <div className="internship-info-item">
        <Text>{position}</Text>
      </div>

      <div className="flex-row-container">
        <div
          className="internship-info-item"
          style={{ flexDirection: "column", justifyContent: "center" }}
        >
          <Text>{countCandidateToInterviews}</Text>
          <Text style={{ fontSize: "10px" }}>Applicants</Text>
        </div>

      </div>
      
      <div className="internship-info-item" style={{ justifyContent: "center" }} onClick={handlePopupClick}>
        <img src={JobLinkIcon} alt="Job Link" style={{ width: "18px", height: "18px" }} />
      </div>

      {!isMobile && ( 
        <div className="internship-info-item" onClick={handleEditClick}>
          <Text>Edit Position</Text>
        </div>
      )}

    </div>
  );
};

export default PositionInfo;
